import axios from 'axios';
export function apiClient(options = {}, baseURL = process.env.VUE_APP_API_END_POINT) {
  const config = {
    baseURL,
    withCredentials: false,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      ...options,
    },
  };

  return axios.create(config);
}

export async function handleResp(action) {
  const dataRet = await action;
  return dataRet?.data;
}
