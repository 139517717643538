import Vue from 'vue';
import Vuex from 'vuex';
// import { isEmpty } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import VuexPersistence from 'vuex-persist';
const vuexLocal = new VuexPersistence({
  key: 'remote_respects_viewer',
  storage: window.localStorage,
});
import EventService from '@/services/EventService';
import { get } from 'lodash';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    event: {},
    eventParams: {},
    userInfo: {},
    supportId: {},
    trackingId: {},
    isProtectedEvent: false,
  },
  mutations: {
    RESET_STORE(state, eventId) {
      state.event = {};
      delete state.userInfo[eventId];
      delete state.supportId[eventId];
      delete state.trackingId[eventId];
      // window.localStorage.removeItem('remote_respects_viewer');
    },
    SET_EVENT_PARAMS(state, eventParams) {
      state.eventParams = eventParams;
    },
    SET_EVENT(state, event) {
      state.event = {
        ...event,
        //youtubeURL: 'https://www.youtube.com/embed/z9q3EggY4Uc',
      };
    },
    SET_USERINFO(state, [userInfo, eventId]) {
      state.userInfo = {
        ...state.userInfo,
        [eventId]: userInfo,
      };
    },
    SET_SUPPORT_ID(state, [supportId, eventId]) {
      state.supportId = {
        ...state.supportId,
        [eventId]: supportId,
      };
    },
    SET_TRACKING_ID(state, [trackingId, eventId]) {
      state.trackingId = {
        ...state.trackingId,
        [eventId]: trackingId,
      };
    },
    SET_PROTECTED_EVENT(state, isProtectedEvent) {
      state.isProtectedEvent = isProtectedEvent;
    },
  },
  actions: {
    async fetchEvent({ commit }, [eventId, password]) {
      try {
        commit('SET_PROTECTED_EVENT', false);
        const eventResult = await EventService.getEventInfo(eventId);
        commit('SET_EVENT_PARAMS', {
          eventId,
        });
        commit('SET_EVENT', eventResult.data);
      } catch (error) {
        const errorApi = get(error, 'response.data.message');
        const errorCode = get(error, 'response.status');
        console.log({ error });
        if (errorApi === "The event is not exit or you don't have permission to view this event" && errorCode === 400) {
          commit('SET_PROTECTED_EVENT', true);
        }
        commit('SET_EVENT', null);
        commit('SET_EVENT_PARAMS', {});
        return errorApi;
      }
    },
    setUserInfo({ commit }, [userInfo, eventId]) {
      commit('SET_USERINFO', [userInfo, eventId]);
    },
    setTracking({ commit }, eventId) {
      commit('SET_TRACKING_ID', [uuidv4(), eventId]);
    },
    setConcludedEvent({ commit }, [eventId]) {
      commit('SET_USERINFO', [{}, eventId]);
    },
    resetStore({ commit }, eventId) {
      commit('RESET_STORE', eventId);
    },
  },
  plugins: [vuexLocal.plugin],
});
