import { apiClient, handleResp } from './utils';
import { empty } from '@/utils';

export default {
  getEventInfo(eventId) {
    return handleResp(apiClient().get('/event/' + eventId));
  },
  createCondolence(eventId, dataInput) {
    return handleResp(apiClient().post(`/event/${eventId}/condolences`, dataInput));
  },
  getCondolences(eventId) {
    return handleResp(apiClient().get(`/event/${eventId}/condolences`));
  },
};
