import Vue from 'vue';
import App from './App';
import router from './router';
import store from './store';

import '@/assets/styles/index.css';

import apolloProvider from '@/aws/appsync';
import Amplify, * as AmplifyModules from 'aws-amplify';
import { AmplifyPlugin } from 'aws-amplify-vue';
import awsmobile from '@/aws/aws-exports';

import VueGtag from 'vue-gtag';

Vue.config.productionTip = false;

Amplify.configure(awsmobile);
Vue.use(AmplifyPlugin, AmplifyModules);
new Vue({
  router,
  store,
  apolloProvider,
  render: h => h(App),
}).$mount('#app');
