<template>
  <div>
    <div v-if="loading_event" class="absolute w-full h-screen bg-white z-10 flex justify-center items-center">
      <div class="pulse-loader">
        <pulse-loader :loading="loading_event" />
      </div>
    </div>
    <div v-else-if="empty(event)" class="absolute w-full h-screen bg-white z-10 flex justify-center items-center">
      <div class="text-red-500 text-4xl rounded-md border-2 border-red-500 bg-red-50 p-4 shadow-md">Invalid event</div>
    </div>
    <router-view v-else />
  </div>
</template>
<script>
import { empty } from '@/utils';
import { mapState } from 'vuex';
import PulseLoader from 'vue-spinner/src/PulseLoader';
export default {
  components: {
    PulseLoader,
  },
  data: () => ({
    loading_event: true,
  }),
  watch: {
    '$route.params.event_id': {
      async handler(value) {
        console.log(value);
        try {
          this.loading_event = true;
          await this.$store.dispatch('fetchEvent', [value]);
          this.doInitEvent();
        } catch (error) {
          console.log({ error });
        } finally {
          this.loading_event = false;
        }
      },
    },
  },
  computed: {
    ...mapState({
      event: 'event',
    }),
  },
  methods: {
    empty,
    doInitEvent() {
      const { customCSS, title } = this.event;
      document.title = title || 'Remote Respects Virtual';
      if (!empty(customCSS)) {
        const styleEle = document.createElement('style');
        styleEle.setAttribute('type', 'text/css');
        styleEle.innerHTML = customCSS;
        document.head.appendChild(styleEle);
      }
    },
  },
};
</script>
