import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store';
import { empty } from '@/utils';

Vue.use(VueRouter);

const routes = [
  {
    path: '/:event_id',
    name: 'Home',
    component: () => import('@/views/Home'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});
router.beforeEach(async (to, from, next) => {
  // const { event_id } = to.params;

  // if (to.name !== 'Home' && empty(store.state.userInfo[event_id])) {
  //   return next({
  //     name: 'Home',
  //     params: to.params,
  //   });
  // } else if (to.name === 'Home') {
  //   if (empty(store.state.userInfo[event_id])) {
  //     await store.dispatch('resetStore', event_id);
  //   } else {
  //     return next({
  //       name: 'Event',
  //       params: to.params,
  //     });
  //   }
  // }
  next();
});

export default router;
